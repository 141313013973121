import React, { useEffect, useState } from 'react'
import LOCAL_IMAGES from "../utils/localImages";
import { ImLocation } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import SmallNavbar from './SmallNavbar';

const Navbar = () => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        // Update isSmallScreen state on window resize
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1100);
        };
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
    
        // Initial check on component mount
        handleResize();
        handleScroll();
    
        // Add event listener for window resize
        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll);
    
        // Remove event listener on component unmount
        return () => {
          window.removeEventListener("resize", handleResize);
          window.addEventListener("scroll", handleScroll);
        };
      }, []);
    const handleFinder = () => {
        navigate('/salonfinder')
    }
  return (
    <>
    {
        isSmallScreen ?  <SmallNavbar />  : (
            <div className={` w-full h-auto bg-white roboto-medium-italic h-28 sticky top-0 z-10 flex justify-between items-center px-6 py-3  transition-all ease-in-out duration-500`}>
        <div>
        <Link to='/'><img src={LOCAL_IMAGES.LOGOS} className='w-32 h-16' alt='logo'/></Link>
        </div>
        <div className='hidden md:flex justify-end items-center gap-x-6 '>
            <Link to='/'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>Home</h1></Link>
            <Link to='/franchise'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>Franchise</h1></Link>
            <a href='https://prosaloncart.com'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>Eshop</h1></a>
    

            <Link to='/gallery'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>Gallery</h1></Link>
            <Link to='/about'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>About us</h1></Link>
            <Link to='/bookAppointment'><h1 className={`text-black font-semibold text-xl cursor-pointer`}>Book Appointment</h1></Link>
            <button className='font-bold text-xl cursor-pointer bg-black text-white px-4 py-2 rounded-lg flex items-center gap-x-2' onClick={handleFinder}>Salon Finder <ImLocation /></button>
        </div>
    </div>

        )
    }
    </>
    
  )
}

export default Navbar