import React from "react";
import LOCAL_IMAGES from "../screens/utils/localImages";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleRedirection = () => {
    navigate("/franchise");
  };
  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full mt-10 md:mt-20 mb-10 roboto-regular ">
    
      <div className="w-full md:w-1/2 h-auto md:h-[500px]  py-2 px-0 md:px-5">
        <img src={LOCAL_IMAGES.aboutus_icon} className="w-full h-full px-10 " alt="about us img" />
      </div>
      <div className="w-full md:w-1/2 h-auto md:h-[500px]  px-10 mb-10">
        <h1 className="w-full  py-2 text-black text-start  font-bold text-2xl md:text-4xl opacity-80 roboto-regular ">
          Who Are We? Best Salon Franchise in India
        </h1>
        <p className="text-xl py-10  opacity-75">
          The Smart Salon is a nascent brand catering to sophisticated consumers
          seeking excellent talent with international expertise who are always
          improving and honing their craft. We are a company that will provide
          you with what you need via education, to upgrade skills of our artists
          to cater to our clients better each day. We have a very particular,
          clear-cut plan that will be carried out over the course of the next
          three to five years, with the main focus being on professional,
          in-depth training. In our initial phase, Monsoon will be the market
          leader with smart placement across top 100 cities. We want to have
          over 100 salons within the next two years, with a further 200 sites
          added in the final two years of our expansion. We have a very solid
          information structure in place to accommodate this kind of growth.
        </p>
        <button
          className="font-medium border border-black opacity-90 px-5 py-2 cursor-pointer"
          onClick={handleRedirection}
        >
          Know more
        </button>
      </div>
    </div>
  );
};

export default Header;
