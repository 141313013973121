import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SubFooter from "../subfooter";
import "./bookappointment.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import { BASE_URL } from "../utils/apiservice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LOCAL_IMAGES from "../utils/localImages";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";
import NewFooter from "../footer";
import { Link } from "react-router-dom";
import { brandsImg } from "../utils/dummydata";

export default function BookAppointment() {
  const salonData = [
    {
      name: "Monsoon",
    },
    {
      name: "Monsoon Pro",
    },
    {
      name: "Smart Salon",
    },
  ];
  const salonService = [
    {
      name: "Hair",
    },
    {
      name: "Beauty",
    },
    {
      name: "Make up",
    },
    {
      name: "Nails",
    },
  ];

  const citiesJson = [
    {
      name: "Andhra Pradesh",
    },
    // {
    //   name: "Arunachal Pradesh",
    // },
    {
      name: "Assam",
    },
    {
      name: "Bihar",
    },
    {
      name: "Chhattisgarh",
    },
    // {
    //   name: "Goa",
    // },
    {
      name: "Gujarat",
    },
    {
      name: "Haryana",
    },
    {
      name: "Himachal Pradesh",
    },
    {
      name: "Jharkhand",
    },
    {
      name: "Karnataka",
    },
    {
      name: "Kerala",
    },
    {
      name: "Madhya Pradesh",
    },
    {
      name: "Maharashtra",
    },
    {
      name: "Manipur",
    },
    {
      name: "Meghalaya",
    },
    // {
    //   name: "Mizoram",
    // },
    // {
    //   name: "Nagaland",
    // },
    {
      name: "Odisha",
    },
    {
      name: "Punjab",
    },
    {
      name: "Rajasthan",
    },
    // {
    //   name: "Sikkim",
    // },
    {
      name: "Tamil Nadu",
    },
    {
      name: "Telangana",
    },
    {
      name: "Tripura",
    },
    {
      name: "Uttar Pradesh",
    },
    {
      name: "Uttarakhand",
    },
    {
      name: "West Bengal",
    },
    {
      name: "Andaman and Nicobar Islands",
    },
    {
      name: "Chandigarh"
    },
    // {
    //   name: "Dadra and Nagar Haveli and Daman and Diu",

    // },
    // {
    //   name: "Lakshadweep",


    // },
    {
      name: 'Delhi'
    },
    // {
    //   name: "Puducherry",
    // },
    {

      name: "Ladakh",
    },
    // {

    //   name: "Lakshadweep",
    // },
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  const [firstname, setFirstName] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [phoneError, setphoneError] = useState();
  const [comment, setComment] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [indiastate, setindiaState] = useState("");
  const [salonType, setSalonType] = useState("");
  const [allcity, setallCity] = useState([]);
  const [service, setService] = useState('')
  const [selectcity, setselectcity] = useState('')
  const labelStyles = {
    color: "grey", // Change this to the color you desire at time and date
  };
  useEffect(() => {
    if (indiastate && salonType) {
      axios


        .post("https://smartsalon.in/api/suggestionForParlors", {
          stateName: indiastate,
          salonType: salonType,
        })
        .then((response) => {
          setallCity(response?.data?.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

  }, [indiastate && salonType]);
  const validatePhoneNumber = () => {
    const Regex = /^([0-9]{10})$/;
    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegexMin2 = /^[a-zA-Z ]{2,50}$/;
    if (phoneno === "") {
      setphoneError("Phone Number field cannot be empty");
    } else if (!Regex.test(phoneno)) {
      setphoneError("Invalid phone number");
    } else {
      setphoneError("");
    }
    if (email === "") {
      setEmailError("Email field cannot be empty");
    } else if (!emailRgx.test(email)) {
      setEmailError("Invalid Email Id");
    } else {
      setEmailError("");
    }
    if (firstname === "") {
      setNameError("Name field cannot be empty");
    } else if (!nameRegexMin2.test(firstname)) {
      setNameError("Invalid Name");
    } else {
      setNameError("");
    }
    if (lastName === "") {
      setLastNameError("Last Name field cannot be empty");
    } else if (!nameRegexMin2.test(lastName)) {
      setLastNameError("Invalid Last Name");
    } else {
      setLastNameError("");
    }
  };
  const handleChangeName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChange = (e) => {
    setPhoneno(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangephoneno = (e) => {
    setPhoneno(e.target.value)

  }
  const handleChangecomment = (e) => {
    setComment(e.target.value);
  };
  const onchangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleChangestate = (e) => {
    setindiaState(e.target.value);
  };
  const handleChangesalonType = (e) => {
    setSalonType(e.target.value);
  };
  const handleChangeservice = (e) => {
    setService(e.target.value)

  }
  const handleChangecity = (e) => {
    setselectcity(e.target.value)

  }
  const BookAppointmentApiCall = () => {
    var data = {
      firstName: firstname,
      lastName: lastName,
      email: email,
      phoneNumber: phoneno,
      date: date,
      time: time,
      serviceType: service,
      city: selectcity,
    };
    axios
      .post("https://smartsalon.in/api/appointmentForm", data)
      .then((response) => {
        alert("response submit sucessfully")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const submithandle = () => {
    if (firstname && lastName && email && phoneno && date && time && service && selectcity) {
      BookAppointmentApiCall();

    }

  };
  return (
    <>
      <Helmet>
        <title>Top Salons in India | Smart Salon | Book an Appointment Today</title>
        <meta name="description" content="Smart Salon - India's leading chain of top-rated salons. Book an appointment now and pamper yourself with top-notch services from our expert stylists - available pan India. Book now!
" />
        <meta name="keywords" content="schedule book an appointment, salon appointment booking, book online salon appointment" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-870359358"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-870359358');
        `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-6SYNR8TGVJ"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6SYNR8TGVJ');
        `}
        </script>
      </Helmet>
      <Box>
        {/* <img
        src="https://monsoonsalon.com/wp-content/uploads/2021/01/0Y2A0396-scaled-e1638956901369.jpg"
        alt=""
        className="responsive"
      /> */}
        <div className="imagecontainer">
          <img
            className="salonImg"
            src={LOCAL_IMAGES.monsoonsalonbook}
            alt="salonimage"
          />
        </div>
        <div style={{ bgcolor: 'white' }}>


          <div
            className="appointmentContainer"
          >

            <div className="bookappointment">
              <span className="onlinetext"
              >      BOOK AN APPOINTMENT ONLINE</span>
              <div sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
                {/* <img src={LOCAL_IMAGES.lineIcon} alt=''
         /> */}
              </div>

            </div>

            <Box
              className="ourbooking"
            >
              <Typography className="bookingtext">
                Our online bookings service operates between 10:00a.m. and 6:00p.m.
              </Typography>
            </Box>
            <div
              style={{
                bgcolor: "white",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: 'white'
              }}
            >
              <Typography style={{ fontSize: 17, color: "#626A70" }}>
                Your data is safe with us! We will only use your details to process
                your salon booking, and won’t share them with third parties.
              </Typography>
            </div>

          </div>
          <div style={{ backgroundColor: 'white', marginTop: '0px', marginBottom: '0px', }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '' }}>
              <Grid container spacing={2}
                sx={{ bgcolor: '', marginLeft: '15px', marginRight: '15px', display: 'flex' }}


              >
                <Grid item lg={6} xs={12}
                  sx={{ bgcolor: '' }}>
                  <Grid container spacing={3} sx={{ bgcolor: '', marginTop: '25px' }}>

                    <Grid item xs={12} sm={12} md={6} lg={6}
                    >
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        size="small"
                        value={firstname}
                        required
                        fullWidth
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangeName}

                      />
                      <Typography style={{ color: "red" }}>{nameError}</Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        value={lastName}
                        onChange={onchangeLastName}
                      />
                      <Typography style={{ color: "red" }}>
                        {lastNameError}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        size="small"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        value={email}
                        onChange={handleChangeEmail}
                      />
                      <Typography style={{ color: "red" }}>{nameError}</Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Phone no"
                        variant="outlined"
                        size="small"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangephoneno}
                        value={phoneno}
                      />
                      <Typography style={{ color: "red" }}>{nameError}</Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          slotProps={{
                            textField: {
                              size: "small",
                              fullWidth: true,
                              error: false,
                            },
                          }}
                          value={date}
                          onChange={(newValue) => setDate(newValue)}
                        />
                      </LocalizationProvider>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label={
                              <span style={labelStyles}>"Appointment Time *"</span>
                            }
                            value={time}
                            onChange={(newValue) => setTime(newValue)}
                            slotProps={{
                              textField: {
                                size: "small",
                                fullWidth: true,
                                error: false,
                              },
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        size="medium"
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          State*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={indiastate}
                          size="small"
                          label="Service Interested In*"
                          onChange={handleChangestate}

                        >
                          {citiesJson?.map((item, index) => {
                            return (
                              <MenuItem value={item.name}> {item.name} </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        size="medium"
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          SalonType*

                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={salonType}
                          size="small"
                          label="SalonType*"
                          onChange={handleChangesalonType}
                          width

                        >
                          {salonData?.map((item, index) => {
                            // console.log('jsonsdata',item)
                            return (
                              <MenuItem value={item.name}> {item.name} </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        size="medium"
                        // style={{width:'502px'}}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          City *

                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectcity}
                          size="small"
                          label="Service Interested In*"
                          onChange={handleChangecity}
                          width
                        // style={{ height: '200px' }}
                        // style={{ minWidth: '400px', height: '100px' }}
                        >
                          {allcity?.map((item, index) =>
                          // console.log("itemdaa",item?.address2)
                          {
                            return <MenuItem value={item?.address2}>{item?.address2} </MenuItem>;

                          }
                          )}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        size="medium"
                        // style={{width:'502px'}}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          Service Interested In*

                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={service}
                          size="small"
                          label="Service Interested In*"
                          onChange={handleChangeservice}
                          width

                        >
                          {
                            salonService?.map((item, index) => {
                              return (<MenuItem value={item.name}>{item.name}</MenuItem>)
                            })
                          }

                        </Select>
                      </FormControl>

                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Comments or Message "
                        id="fullWidth"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangecomment}
                        multiline
                        value={comment}
                        rows={3}
                      />

                    </Grid>
                  </Grid>
                  <div className=""
                    style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={submithandle}
                      sx={{ marginLeft: 0, marginTop: 2, bgcolor: "grey" }}
                    >
                      Book Appointment
                    </Button>
                    <div style={{ backgroundColor: 'white', display: 'flex' }}>

                      <div className="ortextcall">
                        <span className="">OR</span>

                        Call us @<span className="phoneno">  </span><span className="phoneno">7703900678</span>
                      </div>
                    </div>
                  </div>

                  {/* <p className="ortextcall">Call us @   <p className="phoneno">   +919919898006</p>  <p className="phoneno">,+918826345211</p> </p> */}


                </Grid>
                <Grid item lg={6} xs={12} md={6}>
                  <img src={LOCAL_IMAGES.appointment} alt="salonbooking" className="appointmentimg"
                  />

                </Grid>

                {/* 
         
          */}





              </Grid>

            </div>
            <div className="" >
              <h1 className="bookappointmentsalon">Book an Appointment at Smart Salon
              </h1>
              <p className="discriptionText">Contact Smart Salon to Schedule an Appointment.Smart Salon, a leading brand in the beauty market, goes above and above to provide each client a fresh new look. Smart Salon is proud to be the trusted hair and beauty treatment partner of thousands of loyal customers at our 117+ locations in India and overseas. Let us take care of your appearance so that you can relax and enjoy yourself, whether you're getting ready for a party, festival, or just a regular date.</p>
              <p className="discriptionText">In need of a new look for the holiday season? Or perhaps you're looking to reinvent yourself. Then don't hesitate any longer; make an appointment at Smart Salon right away and watch as you emerge from the salon a new, improved person. Making an appointment at Smart Salon couldn't be simpler. In order for our stylists and specialists to better serve you, please fill out the brief form above. Afterward, an executive will contact you to confirm your appointment. Your process of metamorphosis has officially begun.</p>
              <p className="discriptionText"><Link to ='/salonfinder' style={{color:"#d3af37"}}>Our Salon Finder</Link> makes it simple to find and schedule an appointment at a Smart Salon near you. Our goal is for you to leave our salon happy and feeling fabulous after we've given you a new look and answered all your questions about hair and makeup. Schedule your visit right away!</p>
            </div>
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 8, backgroundColor: "white" }}>
              <Typography variant='h2'
                style={{
                  fontSize: 42,
                  fontWeight: '500',
                  color: '#323232',
                  fontFamily: "Poppins"
                }}>Partner Brands</Typography>

            </Box>
            {/* <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
      <img src={LOCAL_IMAGES.lineIcon} alt=''
         />
           </Box> */}
            <div
            // style={{ marginTop: 10, marginBottom: '10px', height: '100px', backgroundColor: '', justifyContent: 'center', alignItems: 'center' }}
            >
              <Slider {...settings}>
                {
                  brandsImg.map((item, index) => {
                    return (
                      <div
                        style={{ height: "50px", width: '50px', backgroundColor: 'green' }}
                      >
                        <img src={item.brandUrl}
                          style={{ height: '60%', width: '50%' }}
                          alt='galleryimages'
                        />
                      </div>
                    )

                  })
                }
              </Slider>
            </div>

          </div>

        </div>

        {/* <Footer/> */}
        <NewFooter />
      </Box>
    </>

  );
}
