import React, { useEffect, useState } from 'react';
import './salondetails.css';
import axios from 'axios';

function SalonsForm() {
  const [formData, setFormData] = useState({
    email: '',
    number: '',
    address1: '',
    address2: '',
    image: null
  });
  const [SalonsDetails,setSalonsDetails]=useState([])

  useEffect(() => {
    const fetchApi = async () => {
        try {
            const data = await axios.post("https://smartsalon.in/api/suggestionForParlors", {
               
            });
            if (data && data?.data && data?.data?.data && data?.data?.data?.length > 0) {
                setSalonsDetails(data?.data);
                console.log("detailsford")
               
            } else {
                setSalonsDetails([]);
            }
        } catch (error) {
            console.error('Error fetching salon data:', error);
        }
    };
    fetchApi();
}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle form submission logic here
  };

  return (
    <div className="App">
      <h1>React Form</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div>
          <label>Number:</label>
          <input 
            type="number" 
            name="number" 
            value={formData.number} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div>
          <label>Address 1:</label>
          <input 
            type="text" 
            name="address1" 
            value={formData.address1} 
            onChange={handleChange} 
            required 
          />
        </div>
        <div>
          <label>Address 2:</label>
          <input 
            type="text" 
            name="address2" 
            value={formData.address2} 
            onChange={handleChange} 
          />
        </div>
        <div>
          <label>Upload Image:</label>
          <input 
            type="file" 
            name="image" 
            accept="image/*" 
            onChange={handleImageChange} 
            required 
            multiple
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default SalonsForm;
