import {
  Box,
  Grid,
  Typography,
  Container,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import InputField from "../../components/customTextinput";
import GoogleMapReact from "google-map-react";
import SubFooter from "../subfooter";
import './contactus.css'
import Footer from "../footer/Footer";


export default function ContactUs() {
  const [firstname, setFirstName] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [phoneError, setphoneError] = useState();
  const [comment, setComment] = useState("");
  const validatePhoneNumber = () => {
    const Regex = /^([0-9]{10})$/;
    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegexMin2 = /^[a-zA-Z ]{2,50}$/;
    if (phoneno === "") {
      setphoneError("Phone Number must filled");
    } else if (!Regex.test(phoneno)) {
      setphoneError("Invalid phone number");
    } else {
      setphoneError("");
    }
    if (email === "") {
      setEmailError("Email must filled");
    } else if (!emailRgx.test(email)) {
      setEmailError("Invalid Email Id");
    } else {
      setEmailError("");
    }
    if (firstname === "") {
      setNameError("Name field cannot be empty");
    } else if (!nameRegexMin2.test(firstname)) {
      setNameError("Invalid Name");
    } else {
      setNameError("");
    }
  };
  const handleChangeName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChangeNumber = (e) => {
    setPhoneno(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangecomment = (e) => {
    setComment(e.target.value);
  };
  const onchangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleSubmitPress = () => {
    validatePhoneNumber();
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 15,
          paddingBottom: 5,
        }}
      >
        <Typography variant="h4" style={{ fontSize: 44, fontWeight: "600" }}>
          Contact Us
        </Typography>
      </Box>
      <Box sx={{ bgcolor: "#F6F6F6;" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6}
            >
              <div
              className="headofficestyle"
              // style={{backgroundColor:'red'}}
              >
                <Box sx={{ marginTop: 2, display: "flex" }}>
                  <HomeIcon
                    style={{ fontSize: 60, color: "white" }}
                    fontSize="large"
                  />
                  <Box
                    sx={{
                      marginLeft: 5,
                    }}
                  >
                    <Typography> Head Office</Typography>
                    <Typography>
                      The Professional Hair Salon & Spa (India) Pvt. Ltd., Plot
                      No - 31, Okhla Industrial Estate,hase - 3, New Delhi -
                      110020
                    </Typography>
                    <Typography>Phone : +91-11-43011111 </Typography>
                  </Box>
                </Box>
                <Box
                  style={{}}
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <MailIcon
                    style={{ fontSize: 60, color: "white" }}
                    fontSize="large"
                  />
                  <Box
                    sx={{
                      marginLeft: 5,
                    }}
                  >
                    <Typography> For Marketing Queries </Typography>
                    <Typography>info@theprofessionalworld.com </Typography>
                  </Box>
                </Box>
                <Box
                  style={{ display: "flex" }}
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <MailIcon
                    style={{ fontSize: 60, color: "white" }}
                    fontSize="large"
                  />
                  <Box
                    sx={{
                      marginLeft: 5,
                    }}
                  >
                    <Typography> For Franchise Queries </Typography>
                    <Typography>info@theprofessionalworld.com</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", marginTop: 2 }}
                >
                  <PhoneInTalkIcon
                    style={{ fontSize: 60, color: "white" }}
                    fontSize="large"
                  />
                  <Box
                    sx={{
                      marginLeft: 5,
                    }}
                  >
                    <Typography>Customer Care Support </Typography>
                    <Typography>+91-9354011796</Typography>
                  </Box>
                </Box>
              </div>
            </Grid>

            <Grid item lg={6} md={6} sm={6}>
              <Box sx={{ marginLeft: 5, marginTop: 3 }}>
                <Typography
                  style={{ color: "#37436c", fontSize: 30, fontWeight: "600" }}
                >
                  Get in Touch
                </Typography>
              </Box>

              <Box
                sx={{
                //   marginLeft: 5,
                  // backgroundColor: "green",
                //   marginTop: 3,
                  display: "flex",
                //   width: "79%",
                  justifyContent: "space-between",
                //   maxWidth: "82%",
                }}
              >
                <Grid container spacing={1}
               >
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      required
                      inputProps={{ style: { color: "grey", fontSize: 18 } }}
                      InputLabelProps={{
                        style: { color: "#626A70", fontWeight: "700" },
                      }}
                      onChange={handleChangeName}
                      value={firstname}
                      // padding="normal"
                    />
                    <Typography style={{ color: "red" }}>
                      {nameError}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} sx={12}
                  sx={{}}>
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      required
                      inputProps={{ style: { color: "grey", fontSize: 18 } }}
                      InputLabelProps={{
                        style: { color: "#626A70", fontWeight: "700" },
                      }}
                      onChange={onchangeLastName}
                      value={lastName}
                      //   style={{ marginLeft: "10px" }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                
                  marginTop: 3,
                
                }}
              >
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      required
                      inputProps={{ style: { color: "grey", fontSize: 18 } }}
                      InputLabelProps={{
                        style: { color: "#626A70", fontWeight: "700" },
                      }}
                      onChange={handleChangeEmail}
                      value={email}
                    />
                    <Typography style={{ color: "red" }}>
                      {emailError}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      // id="outlined-basic"
                      id="phone_number"
                      label="Phone No"
                      variant="outlined"
                      size="small"
                      type="number"
                      onChange={handleChangeNumber}
                      required
                      // inputProps={{ style: {  } }}
                      inputProps={{ style: { color: "grey", fontSize: 18 } }}
                      InputLabelProps={{
                        style: { color: "#626A70", fontWeight: "700" },
                      }}
                      value={phoneno}

                      // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { color: "grey", fontSize: 18 } }}
                    />
                    <Typography style={{ color: "red" }}>
                      {phoneError}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{  marginTop: 3 }}>
                <Box
                // sx={{
                //     width: 520,
                //     maxWidth: "100%",
                // }}
                >
                  <TextField
                    fullWidth
                    label="Comments / Questions "
                    id="fullWidth"
                    required
                    inputProps={{ style: { color: "grey", fontSize: 18 } }}
                    InputLabelProps={{
                      style: { color: "#626A70", fontWeight: "700" },
                    }}
                    multiline
                    onChange={handleChangecomment}
                    value={comment}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                size="large"
                onClick={handleSubmitPress}
                sx={{ marginLeft: 5.4, marginTop: 2, bgcolor: "grey" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        
        </Container>
      </Box>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3504.6701034106613!2d77.267363!3d28.549634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce24a702d1c55%3A0x9a5500a8d4eee992!2sMonsoon%20Salon%20Head%20Office!5e0!3m2!1sen!2sin!4v1695618174537!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* <img src="https://developers.google.com/static/maps/images/landing/hero_mobile_maps_sdks.png" alt="map" style={{ width: '100%', height: 300 }} /> */}
      {/* <SubFooter /> */}
      <Footer/>
      
    </Box>
  );
}
