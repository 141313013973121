import React from 'react'

const Message = ({img,name,desc}) => {
  return (
    <div className='flex flex-col md:justify-start justify-center items-center md:items-start bg-white rounded-lg w-auto md:w-[400px] h-full px-2 py-3'>
        <div className='flex justify-start gap-x-4 items-center'>
        <img src={img} alt='profile' className='w-12 h-12 rounded-full mb-3'/>
        <h1 className='text-xl text-black font-bold '>{name}</h1>
        </div>
        <h1>⭐⭐⭐⭐⭐</h1>
        <div className='text-md text-black mt-5 flex flex-wrap'>
            <p>{desc}</p>
        </div>
    </div>
  )
}

export default Message