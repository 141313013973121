const LOCAL_IMAGES={
    LOGOS:require('../../assets/images/Logoss.png'),
    APP_LOGO:require('../../assets/images/applogo.jpg'),
    SALON_SHEET:require('../../assets/images/salonsheet.jpg'),
    RAJORIGARDEN:require('../../assets/images/rajorigarden.jpg'),
    LOREAL_STORE:require('../../assets/images/lorealstore.jpg'),
    CHANDIGARH:require('../../assets/images/chandigarh.jpg'),
    monsoonlogo:require('../../assets/images/monsoon_logo.png'),
    Monsoonpro:require('../../assets/images/monson_pro.png'),
    smartsalonpro:require('../../assets/images/pro_plus.png'),
    skincoIcon:require('../../assets/images/Skinco.png'),
    kerastaseIcon:require('../../assets/images/Kerastase.jpg'),
    thalgoIcon:require('../../assets/images/Thalgo.png'),
    argatinIcon:require('../../assets/images/Argatin.png'),
    sliderIcon1:require('../../assets/images/slider.jpeg'),
    banner5:require('../../assets/images/banner5.jpg'),
    banner4:require('../../assets/images/banner4.jpg'),
    banner6:require('../../assets/images/banner6.jpg'),
   franchisepoints:require('../../assets/images/franchise_points.png'),
   New_MonsoonPro_Logo : require('../../assets/images/newLogooo.png'),
   NewProLogo: require('../../assets/images/newpro_plus.png'),




    aboutUs:require('../../assets/images/aboutus.jpg'),
    smartsalonGif:require('../../assets/images/logoimg.gif'),
    aboutuspage:require('../../assets/images/about_us_banner.png'),
    about:require('../../assets/images/about.png'),
    hairIcon:require('../../assets/images/hair_icon.png'),
    nailIcon:require('../../assets/images/nail_icon.png'),
    beautyIcon:require('../../assets/images/beautyicon.png'),
    makeupIcon:require('../../assets/images/makeup_icon.png'),

    aboutus_icon:require('../../assets/images/about_us.jpg'),
   quoteicon:require('../../assets/images/quote.png'),
   lineIcon:require('../../assets/images/line.png'),
   inst1Icon:require('../../assets/images/insta1.jpg'),
   inst12Icon:require('../../assets/images/insta2.jpg'),
   inst13Icon:require('../../assets/images/insta3.jpg'),
   inst14Icon:require('../../assets/images/insta4.jpg'),
   inst15Icon:require('../../assets/images/insta5.jpg'),
   inst16Icon:require('../../assets/images/insta6.jpg'),
   inst17Icon:require('../../assets/images/insta7.jpg'),
   inst18Icon:require('../../assets/images/insta8.jpg'),
   inst19Icon:require('../../assets/images/insta9.jpg'),
   inst10Icon:require('../../assets/images/insta10.jpg'),
   inst11Icon:require('../../assets/images/insta11.jpg'),
   inst212Icon:require('../../assets/images/insta12.jpg'),
   appointment:require('../../assets/images/appointment.png'),




   //brands banner 


   argatinIcon:require('../../assets/images/argatin.jpg'),
  springIcon:require('../../assets/images/spring.jpg'),
  skinIcon:require('../../assets/images/skinco.jpg'),
  thalgoIcon:require('../../assets/images/thalgo.jpg'),
  lorealIcon:require('../../assets/images/lorealbrand.png'),


  //
  monsoonsalonbook:require('../../assets/images/monsoonsalon1.jpg'),





   //gallery

   monsoonsalon12:require('../../assets/images/monsoon12.jpg'),
   monsoonsalon13:require('../../assets/images/monsoon13.jpg'),
   monsoonsalon14:require('../../assets/images/monsoon14.jpg'),
   monsoonsalon15:require('../../assets/images/monsoon15.jpg'),
   monsoonsalon16:require('../../assets/images/monsoon16.jpg'),
   monsoonsalon17:require('../../assets/images/monsoon17.jpg'),
   monsoonsalon18:require('../../assets/images/monsoon18.jpg'),
   monsoonsalon19:require('../../assets/images/monsoon19.jpg'),
   monsoonsalon20:require('../../assets/images/monsoon20.jpg'),
   monsoonsalon21:require('../../assets/images/monsoon21.jpg'),
   monsoonsalon22:require('../../assets/images/monsoon22.jpg'),
   monsoonsalon23:require('../../assets/images/monsoon23.jpg'),
   monsoonsalon24:require('../../assets/images/monsoon24.jpg'),
   monsoonsalon25:require('../../assets/images/monsoon25.jpg'),
   monsoonsalon26:require('../../assets/images/monsoon26.jpg'),
   monsoonsalon27:require('../../assets/images/monsoon27.jpg'),


   proplussalon:require('../../assets/images/proplussalon.png'),
   monsoonprosalon:require('../../assets/images/monsoonprosalon.png'),
   monsoonsalon:require('../../assets/images/monsoonsalon.png'),




































    

}
export default LOCAL_IMAGES;