import React from 'react'
import { MdOutlineMenu } from "react-icons/md";
import LOCAL_IMAGES from "../utils/localImages";
import { ImLocation } from 'react-icons/im';
import Sidebar from '../../components/sidebar';
import { Link, useNavigate } from 'react-router-dom';

const SmallNavbar = () => {
    const navigate = useNavigate();
    const handleFinder=()=>{
        navigate('/salonfinder')
    }
  return (
    <div className='flex justify-between w-full items-center  px-6 py-2 sticky top-0 z-10 bg-white'>
       {/* <MdOutlineMenu className='text-3xl font-bold'/> */}
       <Sidebar />
        <Link to="/"><img src={LOCAL_IMAGES.LOGOS} className='w-28 h-14'/></Link>
        <ImLocation className="text-black text-2xl" onClick={handleFinder}/>
    </div>
  )
}

export default SmallNavbar