import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { BsInstagram } from 'react-icons/bs'
import { GrYoutube } from 'react-icons/gr'
import { AiFillTwitterCircle } from 'react-icons/ai'
import { BiLogoPinterest } from 'react-icons/bi'
import { FaLinkedin } from 'react-icons/fa'
import './Footer.css'


const Footer = () => {
    return (
        <div className="footercontainer">
            <Container >
            <Grid container
                // item lg={12}
                // spacing={6}
            >

                <Grid item
                    lg={3}
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                        // backgroundColor: 'red',
                   
                    }}>
                    <div>

                        <Typography
                            variant="h5"
                            sx={{
                                color: 'white',
                                backgroundColor: '',
                                marginTop: 2



                                // marginLeft: 3
                            }}
                        >Information</Typography>
                        <ul className="blogstyle">

                            {/* <Link to={'./privacypolicy'}> */}
                                <li style={{ color: 'white',fontSize:'17px',fontWeight:'500' }}>Carrer</li>
                            {/* </Link> */}
                            <Link to={'./privacypolicy'}>
                                <li style={{ color: 'white',fontSize:'17px',fontWeight:'500' }}>Privacy Policy</li>
                            </Link>

                        </ul>

                    </div>


                </Grid>
                <Grid item lg={3}
                    xs={12}
                    sm={6}
                    md={4}

                    sx={{
                        color: 'white',
                        // backgroundColor: 'green'
                    }}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: 'white',
                            marginTop: 2
                            // marginLeft: 3
                        }}
                    >Join Us</Typography>
                    {/* <Typography>djkjkdfjkd</Typography> */}
                    <div style={{ right: 40 }} >

                        <ul className="blogstyle">
                            {/* <Link to='corporateEvent'>
                                <li style={{ color: 'white' ,fontSize:'17px',fontWeight:'500'}}>Corporate Events</li>
                            </Link> */}
                            <Link to={'/contactus'}>
                                <li style={{ color: 'white' ,fontSize:'17px',fontWeight:'500'}}>Contact Us</li>
                            </Link>
                            <Link to={'/bookappointment'}>
                                <li style={{ color: 'white',fontSize:'17px',fontWeight:'500' }}>BookAppointment</li>
                            </Link>
                            <Link to='/franchise'>
                                <li style={{ color: 'white' ,fontSize:'17px',fontWeight:'500'}}>Franchise</li>
                            </Link>
                            {/* <li style={{ color: 'white' }}>Employee Center</li> */}
                        </ul>
                    </div>


                </Grid>
                <Grid item lg={3}
                    xs={12}
                    sm={6}
                    md={4}

                    sx={{
                        color: 'white',

                        backgroundColor: ''
                    }}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: 'white',
                            marginTop: 2
                        }}
                    >Our Social Media</Typography>
                    <Box style={{ marginTop: 15, }}
                        sx={{ marginBottom: 1 }}>

                        <Box
                            sx={{ justifyContent: "space-between", alignItems: "center", display: 'flex', width: '100%',maxWidth:'120px' }}>
                            <a href="https://www.facebook.com/monsoonsalon/">
                                <FacebookOutlinedIcon size={28}  style={{color:'white'}} />

                            </a>
                            <a href="https://www.instagram.com/monsoonsalon/"
                            className="footer-icon">
                                <BsInstagram size={24} 
                                style={{marginLeft:'7px',color:'white'}}/>
                            </a>
                            <a href="https://www.youtube.com/user/monsoonsalon">
                                <GrYoutube size={28}  style={{color:'white'}} />

                            </a>
                        </Box>
                        <Box
                            sx={{ justifyContent: "space-between", alignItems: "center", display: 'flex', width: '100%' ,maxWidth:'120px'}}>

                            <a href="https://twitter.com/monsoonsalon">
                                <AiFillTwitterCircle size={28}  style={{color:'white'}}/>
                            </a>
                            <a href="https://in.pinterest.com/monsoonsalon/">
                                <BiLogoPinterest size={28} style={{color:'white'}} />

                            </a>
                            <FaLinkedin size={25}  style={{color:'white'}}/>
                        </Box>
                    </Box>


                </Grid>
                <Grid item lg={3}
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{
                        // backgroundColor: 'blue',
                        
                    }}
                >
                    <Typography
                        variant="h7"
                        sx={{
                            color: 'white',
                            marginTop: 2


                        }}
                    >Contact Us</Typography>
                    <Box style={{ marginTop: 15 }}>

                        <Box
                            sx={{
                                // backgroundColor: 'green',
                                display: 'flex',
                                // alignItems:'center'
                            }}>
                            <Typography
                                sx={{
                                    color: 'white',
                                    // width:'50%'

                                }}>#TheAddress, Plot No. 62, Okhla Phase III, Okhla Industrial Estate, New Delhi-110020</Typography>
                        </Box>
                        <Box
                            sx={{
                                // backgroundColor: 'green',
                                display: 'flex',
                                // justifyContent:'center'
                                alignItems: 'center'
                            }}>
                            <CallOutlinedIcon style={{ color: 'white', height: 19, width: 20 }}
                            />
                            <Typography sx={{
                                color: 'white',

                            }}>7703900678</Typography>
                        </Box>
                        <Box
                            sx={{
                                // backgroundColor: 'green',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <EmailOutlinedIcon style={{ color: 'white', height: 19, width: 20 }} />
                            <Typography
                                sx={{
                                    color: 'white',

                                }}>info@theprofessionalworld.com</Typography>
                        </Box>
                    </Box>

                </Grid>

            </Grid>
            </Container>

        </div>
    )
}
export default Footer;