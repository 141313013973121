
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Container, Grid, Paper, ThemeProvider, Typography, createTheme, } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './saloncart.css'
import { useNavigate,useLocation } from 'react-router-dom';
const theme = createTheme({
    // needs to customise which component we want 
    components: {
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h6"
                    },
                    style: {
                        fontSize: 11,
                        // color:'red'

                    },

                },
                {
                    props: {
                        variant: "body3"
                    },
                    style: {
                        fontSize: 1

                    },

                },
            ]
        }
    }

})

export default function SalonCart(props) {
    const { salonDataItem } = props
    // console.log("salonDataItem",salonDataItem)
   const navigate= useNavigate()

    const salondiscription=()=>{
        console.log("datadiscption")
    }
    const cardpress=(item)=>{
        navigate('/salondiscription',
        {state:{data:item}}
        )
     
    
      }
    return (
        <div>
            {/* {loading ? (
      <p>Loading...</p>
    ) : (
      <ul>
        {data.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
    )} */}
            <Container>

                {/* <Grid container spacing={5}> */}
                <ThemeProvider theme={theme}>
                    <Paper elevation={1}
                        sx={{ backgroundColor: "white", padding: "0px", marginTop: 5, paddingLeft: '16px', paddingRight: '16px', height:'300px'}}
                        className="paperContainer"
              onClick={() => cardpress(salonDataItem)}
                
                        

                    >
                        <h4 
                        >{salonDataItem?.name}</h4>
                        <Box sx={{
                            //    display: 'flex',
                            //     justifyContent: 'space-between', 
                            // paddingTop:20

                        }}>
                            <Box sx={{ height: '150px', width: '200px', borderRadius: '5px', transform: 'scale(1.0)', transition: "transform 0.3s ease" }}>


                                <img
                                    style={{
                                        height: "100%",
                                        width: "100%"
                                    }}
                                    src={salonDataItem.images[0].appImageUrl}
                                    alt='salonimages' />

                            </Box>
                            <Box sx={{ height: '50px', width: '300px', borderRadius: '5px', transform: 'scale(1.0)', transition: "transform 0.3s ease" }}>


                                {/* <img
                                    style={{
                                        height:"100%",
                                        width:"100%"
                                    }}
                                    src={salonDataItem.images[0].appImageUrl}
                                    alt='salonimages'/> */}
                                <Typography style={{ maxWidth: '100%', width: '100%', }}>{salonDataItem.address}</Typography>
                                <Typography style={{ maxWidth: '100%', width: '100%', }}>{salonDataItem.address2}</Typography>


                            </Box>


                            <Box>

                                <Box sx={{
                                    display: 'flex',
                                    // justifyContent: 'space-between',

                                }}>
                                    {/* <Box sx={{}}> */}

                                    {/* </Box> */}
                                    {/* <Box sx={{ backgroundColor: '', justifyContent: 'center', alignSelf: 'center', }}>
                                        <LocationOnOutlinedIcon />
                                        <Typography>17.4kms</Typography>
                                    </Box> */}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                }}>

                                    {/* <Typography>place</Typography>
                                    <Typography>For Male Hair Cut</Typography> */}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    {/* <Box sx={{}}>

                                        <Typography>Monsoon</Typography>
                                        <Typography variant='h5'
                                        style={{fontSize:'15px',fontWeight:'500',maxWidth:'60%'}}
                                        >{salonDataItem.address2}</Typography>
                                    </Box> */}
                                    {/* <Typography>place</Typography> */}
                                </Box>
                            </Box>

                        </Box>

                          {/* <Typography onclick={salondiscription}>onclick</Typography> */}
                    </Paper>
                </ThemeProvider>

                {/* </Grid> */}

            </Container>

        </div>
    )
}




