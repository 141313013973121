import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Container } from '@mui/material';
import './privacypolicy.css'
import Footer from '../footer/Footer';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function PrivacyPolicy() {
  return (
    <>
      <div className='privacypolicy'>
        <h4 style={{ fontSize: 44, fontWeight: '600' }}> Privacy Policy</h4>
      </div>



      <Container sx={{}}>

        <Accordion sx={{ padding: 1, }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }}
            />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography 
            style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins'}}>What kind of data do we gather?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You provide information to us when you complete a form.
              You might be prompted to submit your name or email address while placing a purchase or
              registering on our website, as appropriate. But you are welcome to browse our website
              anonymously.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins'}}>Why do we utilize the information you provide?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We may use any information we get from you for any of the following purposes:
              To enhance customer service (your information enables us to address your support needs and
              requests more efficiently)
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins'}}>How is your information safeguarded by us?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Whenever you enter, submit, or access your personal information, we put a number of security
              measures in place to keep it safe.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins' }}>Are cookies used by us?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, cookies are little files that websites or their service providers upload to your computer&#39;s
              hard drive via your web browser (if you permit them to do so). Cookies help these websites or
              their systems identify your browser and remember certain information.
              In order to provide better site experiences and tools in the future, we utilize cookies to gather
              aggregate data on site traffic and site activity. In order to help us better understand our site
              visitors, we might enter into contracts with outside service providers. The information gathered
              on our behalf may only be used by these service providers to support and enhance our business
              operations.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content" 
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins' }}> Unless specified below, we won't share your personal information:</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Regular business purposes allow our Merchants to access your information.
              Licensed third-party service providers may obtain your data.  Third parties provide some goods and services. To send and distribute marketing and administrative information, we use "Third Party Service Providers." We may share Personal Information with Service Providers for order fulfillment.

              deliver, send emails or letters, run competitions or sweepstakes, purge customer lists of redundant information, analyze data, help with marketing, offer links and search results, accept credit card payments, run the website, debug, and support Personal information from "Affiliates." may be needed for administrative purposes. We may also notify Service Providers. When you order a service or product, we give your credit card information to the card issuer to confirm payment and your address to the delivery service.
              For payment processing, we may give our bank an Affiliate's information. We recommend Monsoon Salon Merchants and Third Party Service Providers adopt privacy policies. The privacy policies of third parties who use your PII are beyond our control.
              Data may be transferred during business transfers.
              We can merge or be acquired like any company. Future business would receive our data, including personally identifiable information. This Privacy Policy would apply to PII.
              Their and our safety may require sharing your information.
              To comply with legal obligations or judicial orders, enforce or apply our website's terms of usage, privacy policy, or other agreements, or defend the rights, property, or safety of the website, its users, or other parties, we may disclose Personally Identifiable Information.
              Affiliates may obtain your data.
              Upon consent, we may share your personally identifiable information with our associated organizations to inform you of new services, unique offers, promotions, or information.
              Website Privacy Policies Outside
              Only data use and disclosure are covered by this privacy policy. Data collection, usage, and disclosure practices may vary on linked websites. We recommend reading such websites' privacy policies. Third-party policies are not ours.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins' }}>Unrelated Privacy Concerns?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Kids. Under-18s are not permitted to use this website. No element of the website is intended to
              attract anyone under the age of 18, and we do not gather or retain information from anyone
              known to be under that age. Children cannot purchase anything at Monsoon Salon. We do
              occasionally sell goods for children that adults can buy. You may only use Monsoon Salon if
              your parent or legal guardian is present if you are under the age of 18.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins'}}>Public Areas.</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>

              On our website, there might be sections dedicated to allowing you to openly share personal
              information, interact with others, provide product reviews, and upload media. This information
              could be read, gathered, and utilized by others since it might be available to other customers
              and businesses, show up on other websites, and result from web searches. Please use caution
              when publishing any personal information online since we have no control over who sees your
              postings or what other users may do with the information you willingly provide.
              Online Privacy Policy Only
              This online privacy policy only applies to information collected through our website and not to
              offline information.
              Your Consent
              You accept our website&#39;s privacy policy by using it.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins' }}>Changes to Privacy Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              This Privacy Policy is subject to change at any moment, and any updates will be posted in the
              website&#39;s &quot;Privacy Policy&quot; section. Upon its posting on the website, the updated privacy policy
              goes into effect right away. Each User shall be responsible for frequently reviewing the Website
              and the Privacy Policy to become aware of any updates. You will be deemed to haveacknowledged and accepted the amended Privacy Policy&#39;s terms if you continue to use the
              website after it has undergone adjustments.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins' }}>Contacting Us</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              You can get in touch with us using the details provided below if you have any queries about our
              privacy statement.
              info@monsoonsalon.com
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion sx={{ padding: 1, marginTop: 1 }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon
              fontSize='large'
              style={{ color: 'black', }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700',color:'#000000' , fontFamily: 'Poppins'}}>How do we protect your information?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
              malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

      </Container>
      <Footer/>


    </>

  )
}
