import React from 'react'

const ServiceCart = ({img,name,desc}) => {
  return (
    <div className='flex flex-col md:justify-start justify-start items-start md:items-start bg-white rounded-lg w-auto md:w-[300px] h-full px-2 py-3'>
    <div className='flex justify-start gap-x-4 items-center'>
    <img src={img} alt='profile' className='w-18 h-18 rounded-full mb-3'/>
    <h1 className='text-2xl text-black font-bold roboto-medium-italic'>{name}</h1>
    </div>
    <div className='text-lg text-black mt-5 flex flex-wrap roboto-medium-italic'>
        <p className='text-lg roboto-medium-italic opacity-75'>{desc}</p>
    </div>
</div>
  )
}

export default ServiceCart