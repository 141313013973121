import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "./screens/homepage/home";
import About from "./screens/about/About";
import Header from "./screens/header/Header";
import "./App.css";
import SmartSalon from "./screens/smartsalon";
// import Franchise from "./screens/franchise";
import Career from "./screens/career";
import PrivacyPolicy from "./screens/privacypolicy";
import ContactUs from "./screens/contactus";
import CorporateEvent from "./screens/corporateEvents";
import BookAppointment from "./screens/bookappointment";
import Gallery from "./screens/gallery";
import Video from "./screens/video";
import SalonFinder from "./screens/salonfinder";
import Salondiscription from "./screens/salonsDiscription";
import FormPage from "./screens/formpage";
import Newfranchise from "./screens/newfranchisepage";
import ReactGA from "react-ga";
import CustomDesign from "./screens/salonDesign";
import PrivacyAndPolicy from "./screens/privacypolicy/privacy";
import Navbar from "./screens/navbar";
import NewFooter from "./screens/footer";
import SalonFinders from "./screens/salonfinder/SalonFinders";
import SalonDeatils from "./screens/OfferFrom";
import SalonForm from "./screens/salonsForm";
import CustomDesignSalons from "./screens/salonDesign/customsalon";

const TRACKING_ID = "G-H8LMPD3V4F"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 1000);
  }

  return (
    <div className="roboto-regular">
    {
    !loading && (
      <BrowserRouter>
        {window.location.pathname !== "/customSalon" && <Navbar />}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/monsoon" element={<SmartSalon />} />
          <Route path="/monsoonpro" element={<SmartSalon />} />
          <Route path="/proplus" element={<SmartSalon />} />


          <Route path="/franchise" element={<Newfranchise />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/corporateEvent" element={<CorporateEvent />} />
          <Route path="/bookAppointment" element={<BookAppointment />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/video" element={<Video />} />
          <Route path="/salonfinder" element={<SalonFinders />} />
          <Route path="/salondescription" element={<Salondiscription />} />
          <Route path="/formpage" element={<FormPage />} />
          <Route path="/offerform" element={<SalonDeatils />} />
          <Route path="/salonfinder/:id" element={<CustomDesign />} />
          <Route path="/SalonForm"  element ={<SalonForm/>}/>
          <Route path="/CustomDesignSalons" element={<CustomDesignSalons/>}/>
          <Route path="/*" element={<Newfranchise/>}/>
        </Routes>
        {/* <NewFooter /> */}
      </BrowserRouter>
    )}
    </div>
  );
}
