import React from 'react'

const InstagramCart = ({img}) => {
  return (
    <div className='w-[100px] md:w-[250px] cursor-pointer hover:brightness-50 transition-all duration-300 ease-linear'>
        <img src={img} className='w-full '/>
    </div>
  )
}

export default InstagramCart