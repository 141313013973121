
import React, { useEffect, useState } from 'react'
import SalonCart from '../../components/saloncart'
import { Container, Typography, Box } from '@mui/material'
import Footer from '../footer/Footer'
import axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Loader from '../loader'
import LOCAL_IMAGES from '../utils/localImages'
import './smartsalon.css'
import { Helmet } from 'react-helmet';

export default function SmartSalon() {
  // let {id}=useParams()
  // console.log("ddfjksjkdfsjkfds",id)
  const [salonData, setSalonData] = useState([])
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = location.state
  console.log("datamonsoonsalon", data)

  useEffect(() => {
    axios.post('https://smartsalon.in/api/salons', {
      salonType: data
    })
      .then(function (response) {
        // handle success
        console.log("gettingresponse for salons", response?.data);
        setSalonData(response?.data?.data)
        setLoader(false)

      })
      .catch(function (error) {
        // handle error
        setLoader(true)
        console.log(error);
      });
  }, [])
  const onpressFranchise = () => {
    navigate('/franchise')
  }
  return (
    !loader ?
      <div>
        {/* <Container>
          <h1>OUR  SALONS </h1>

        </Container> */}
   

        
        <div
        // style={{ display: 'flex', justifyContent: "center", alignItems: 'center', }}
        >
          <div

          // style={{ maxWidth: '1600px', width: '100%', display: 'flex', gap: "10px", flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}
          >
            {
              data === "Monsoon" &&
              <>
              <Helmet>
      <title>Smart Salon is an extensive chain of salons in India</title>
      <meta name="description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities." />
      <meta name="classification" content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs." />
      <meta property="og:title" content="Smart Salon is an extensive chain of salons in India" />
      <meta property="og:image" content="https://smartsalon.in/SmartSalon/monsoon" />
      <meta property="og:description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities." />
      <meta property="og:locale" content="en_IN" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://smartsalon.in/SmartSalon/monsoon" />
      <meta property="og:site_name" content="Search Results | Smart Salon Monsoon" />
      <link rel="canonical" href="https://smartsalon.in/SmartSalon/monsoon" />
      {/* Combine all robots meta tags into one */}
      <meta name="robots" content="follow, index, all, noodp, noydir, max-snippet:-1, max-image-preview:large" />
      <meta name="revisit-after" content="2 days" />
      <meta name="googlebot" content="index,follow" />
      <meta name="expires" content="never" />
      {/* Use httpEquiv for equivalent HTTP header */}
      <meta httpEquiv="language" content="English" />
      <meta name="document-type" content="public" />
      <meta name="author" content="SmartSalon" />
    </Helmet>
                <div>
               
                  <div className='logostyle'>
                    <img src={LOCAL_IMAGES.monsoonlogo} alt='logo icon'
                    />

                  </div>
                  <div >
                    <div className='videodatacontiner'>
                      <iframe width="100%" height="672" src="https://www.youtube.com/embed/9uJkaJtx4Ng" title="Step into luxury - Monsoon Salon#monsoon #salon #monsoonsalon #haircolor #hair #ytshorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p className='beautysalon'>
                      Here at Monsoon Salon, we&#39;re proud to present a unique franchise opportunity at the intersection of
                      aesthetics, technology, and savvy business practise. Since 2013, our India-based company
                      has been at the forefront of the beauty business, providing clients with unforgettable services that
                      boost their self-esteem and make them feel like a million bucks.
                    </p>

                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '300px', fontWeight: '600' }}> Franchise Overview:</p>

                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Cutting-Edge Techniques &amp; Trendy Products We offer a full range of services to help our customers
                          always look their best, including haircuts, style, colouring, manicures, pedicures, and facials.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our salons are supported by a staff of highly trained specialists with years of combined experience,
                          guaranteeing treatments that are second to none and always exceed client expectations.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Each franchise is tailored to fit its specific market by providing services that are tailored to the needs
                          of the local population. This allows for maximum market penetration and customer satisfaction.</p>
                      </li>
                    </ul>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}> Why Choose Us?:</p>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our franchise concept was carefully crafted to be easily replicated, guaranteeing a constant
                          representation of our brand while still allowing for local adaptation.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>We provide our franchisees with in-depth training, as well as ongoing assistance, to help them run
                          their businesses at optimal efficiency and success.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our salons are committed to social responsibility, so we only use environmentally friendly products
                          and processes that not only make clients look and feel better, but also keep our planet safe.</p>
                      </li>
                    
                    </ul>
                  </div>



                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}>Franchisee Requirements:</p>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}> range for potential investments 55 lac to 1 cr</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Standard Salon Size: Min 1000 Square Feet.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>A background in the beauty industry is helpful, but it&#39;s not required; we&#39;ll provide you with all the
                          training you need to succeed.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Alignment with the brand&#39;s core principles of excellence, integrity, and customer happiness..</p>
                      </li>
                    </ul>
                  </div>

                  <div className='queryStyle'>
                    <p className='franchise'>For More   Queries Please Submit details in   Franchise </p>
                    <div className='franchiseButton'>
                      <div className='button' onClick={onpressFranchise}>
                        <p className='enqury'> Franchise Enqury</p>
                      </div>
                    </div>
                  </div>


                </div>
              </>


            }
            {
              data === "Monsoon Pro" &&
              <>
                 <Helmet>
        <title>Smart Salon is an extensive chain of salons in India</title>
        <meta name="description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities." />
        <meta name="classification" content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs." />
        <meta property="og:title" content="Smart Salon is an extensive chain of salons in India" />
        <meta property="og:image" content="https://smartsalon.in/SmartSalon/monsoonpro" />
        <meta property="og:description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities." />
        <meta property="og:locale" content="en_IN" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://smartsalon.in/SmartSalon/monsoonpro" />
        <meta property="og:site_name" content="Search Results | Momsoon Pro" />
        <link rel="canonical" href="https://smartsalon.in/SmartSalon/monsoonpro" />
        <meta name="robots" content="follow, index, all" />
        <meta name="robots" content="noodp,noydir" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large" />
        <meta name="revisit-after" content="2 days" />
        <meta name="googlebot" content="index,follow" />
        <meta name="expires" content="never" />
        <meta http-equiv="language" content="English" />
        <meta name="document-type" content="public" />
        <meta name="author" content="SmartSalon" />
      </Helmet>
                <div>
                
                  <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                    <img src={LOCAL_IMAGES.Monsoonpro} alt='monsoon pro'
                    />
                  </Box>
                  <div className='videodatacontiner'>
                    <iframe width="100%" height="672" src="https://www.youtube.com/embed/vVxTlq2jMaU" title="MONSOON SALON GORAKHPUR | BEST SALON IN GORAKHPUR | PREMIUM CLASS BEAUTY SERVICES IN GORAKHPUR." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>

               
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <p className='beautysalon'>
                      Welcome to Monsoon Pro Salon, where beauty, innovation, and business
                      acumen converge to offer a remarkable franchising opportunity! Rooted in 58
                      cities and 4 Union territories, we have been pioneers in the beauty industry
                      since 2013, meticulously crafting unparalleled experiences for our customers,
                      ensuring they step out with confidence and a dash of allure.
                    </p>

                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '300px', fontWeight: '600' }}> Franchise Overview:</p>

                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>We specialize in a wide array of services, including
                          haircuts, styling, coloring, manicures, pedicures, and facials, utilizing cutting-
                          edge technology and the latest trends to keep our clientele looking their best.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our salons are backed by a team of seasoned
                          professionals with a wealth of experience, ensuring expertly delivered
                          services that meet and exceed customer expectations.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Each franchise is designed to adapt to its
                          unique market, offering services that cater specifically to its locality, ensuring
                          optimum market penetration and customer satisfaction.</p>
                      </li>
                    </ul>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}> Why Choose Us?:</p>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our franchise model is intricately designed to be
                          easily replicable, ensuring consistency in brand experience while allowing
                          room for creativity and customization to meet local needs.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>We equip our franchisees with the necessary
                          tools, training, and continuous support to ensure they operate at peak
                          performance, fostering business growth and sustainability.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>In a commitment to social responsibility, our salons
                          are devoted to eco-friendly practices, eco-friendly products with bio
                          degradable waste and methods that not only enhance beauty but also protect
                          our planet..</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Benefit from our powerful marketing strategies and
                          materials, honed to build a compelling brand presence and attract a consistent
                          flow of customers done online and offline on brand level.</p>
                      </li>
                    </ul>
                  </div>


                 

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                      <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}>Franchisee Requirements:</p>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>35 lac to 55 lac</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Minimum Salon Area - 700sq to 1000sq</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Prior experience in the beauty industry is preferred but not
                          mandatory, as our comprehensive training will empower you with the essential
                          skills and knowledge.</p>
                      </li>
                      <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>A commitment to excellence, integrity, and customer satisfaction,
                          aligning with our brand’s core values.</p>
                      </li>
                    </ul>
                  </div>


                  <div className='queryStyle'>
                    <p className='franchise'>For More   Queries Please Submit details in   Franchise </p>
                    <div className='franchiseButton'>
                      <div className='button' onClick={onpressFranchise}>
                        <p className='enqury'> Franchise Enqury</p>
                      </div>
                    </div>
                  </div>

                </div>
              </>

            }
            {
              data === "Smart Salon" &&

<>
  <Helmet>
        <title>Smart Salon is an extensive chain of salons in India</title>
        <meta name="description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities." />
        <meta name="classification" content="Salon chain with a wide range of offerings, from hair care to bridal makeup to nail care. Provides salon franchise options entrepreneurs."/>
        <meta property="og:title" content="Smart Salon is an extensive chain of salons in India"/>
        <meta property="og:image" content="https://smartsalon.in/SmartSalon/proplus" />
        <meta property="og:description" content="The salon chain also provides nail maintenance and bridal makeup. Entrepreneurial salon franchise opportunities."/>
        <meta property="og:locale" content="en_IN" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://smartsalon.in/SmartSalon/proplus"/>
        <meta property="og:site_name" content="Search Results |SmartSalon ProPlus" />
        <link rel="canonical" href="https://smartsalon.in/SmartSalon/proplus" />
        <meta name="robots" content="follow, index, all, noodp, noydir, max-snippet:-1, max-image-preview:large" />
        <meta name="revisit-after" content="2 days" />
        <meta name="googlebot" content="index,follow" />
        <meta name="expires" content="never" />
        <meta http-equiv="Content-Language" content="English" />
        <meta name="document-type" content="public" />
        <meta name="author" content="SmartSalon" />
      </Helmet>

              <div>

                <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '45px' }}>
                  <img src={LOCAL_IMAGES.smartsalonpro} alt='smart salon pro'
                  />
                </Box>
    

    <div className='videodatacontiner'>
<iframe width="100%" height="672" src="https://www.youtube.com/embed/4nH8362EcDc" title="Your Beauty, Upgraded: Be U Salon Is Pro Plus Smart Salon" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                    </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <p className='beautysalon'>
                    Welcome to Pro Plus Salon, where beauty meets innovation. Our salon
                    franchise offers an exciting opportunity to empower entrepreneurs to start
                    their own business with Optimal utilization of 300-600 sq. ft. of retail space
                    with integration of tech to enhance customer experience.
                  </p>

                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>

                  <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                    <p style={{ color: "#323232", fontSize: '18px', maxWidth: '300px', fontWeight: '600' }}> Franchise Overview:</p>

                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our smaller salon spaces are thoughtfully designed to
                        create an intimate and inviting atmosphere for clients. This ambiance
                        promotes a sense of exclusivity and personal attention that sets us apart</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>In our boutique size, we provide a comprehensive range
                        of high-quality beauty services, including haircuts, styling, coloring, manicures,
                        pedicures and makeup ensuring our clients leave feeling pampered and
                        rejuvenated</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our compact spaces are designed for maximum efficiency,
                        allowing for quick turnarounds and minimal operational overhead, which can
                        lead to a quicker return on investment for franchises.</p>
                    </li>
                  </ul>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                    <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}> Why Choose Us?:</p>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our smaller salon spaces are thoughtfully designed to
                        create an intimate and inviting atmosphere for clients. This ambiance
                        promotes a sense of exclusivity and personal attention that sets us apart</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>In our boutique size, we provide a comprehensive range
                        of high-quality beauty services, including haircuts, styling, coloring, manicures,
                        pedicures and makeup ensuring our clients leave feeling pampered and
                        rejuvenated</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our compact spaces are designed for maximum efficiency,
                        allowing for quick turnarounds and minimal operational overhead, which can
                        lead to a quicker return on investment for franchises.</p>
                    </li>
                  </ul>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                    <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}> Franchise Overview:</p>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our smaller salon spaces are thoughtfully designed to
                        create an intimate and inviting atmosphere for clients. This ambiance
                        promotes a sense of exclusivity and personal attention that sets us apart</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>In our boutique size, we provide a comprehensive range
                        of high-quality beauty services, including haircuts, styling, coloring, manicures,
                        pedicures and makeup ensuring our clients leave feeling pampered and
                        rejuvenated</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Our compact spaces are designed for maximum efficiency,
                        allowing for quick turnarounds and minimal operational overhead, which can
                        lead to a quicker return on investment for franchises.</p>
                    </li>
                  </ul>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <ul style={{ backgroundColor: '', maxWidth: '900px' }}>
                    <p style={{ color: "#323232", fontSize: '18px', maxWidth: '900px', fontWeight: '600' }}>Franchisee Requirements:</p>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>15 lac to 35 lac</p>
                    </li>
                    <li className='bulletsStyle'>
                        <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>Minimum Salon Area - 300sq to 700sq</p>
                      </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>A genuine passion for the beauty industry and a commitment to providing exceptional service to clients are essential qualities for prospective franchisees.</p>
                    </li>
                    <li className='bulletsStyle'>
                      <p style={{ color: '#323232', fontSize: '18px', maxWidth: '900px', lineHeight: '30px', fontWeight: '400' }}>A focus on delivering a personalized and exceptional client experience that matches the brand's commitment to quality. Embrace the charm of a boutique salon franchise with Pro Plus Salons. Step into the world of beauty and entrepreneurship, where intimate spaces can yield significant success. Join us and start your journey towards owning a thriving salon in a compact yet luxurious setting!</p>
                    </li>
                  </ul>
                </div>
                <div className='queryStyle'>
                  <p className='franchise'>For More   Queries Please Submit details in   Franchise </p>
                  <div className='franchiseButton'>
                    <div className='button' onClick={onpressFranchise}>
                      <p className='enqury'> Franchise Enqury</p>
                    </div>
                  </div>
                </div>






              </div>
</>


            }


          </div>
        </div>


        <Footer />


      </div> : <Loader />



  )
}
