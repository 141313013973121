import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './gallery.css'
import LOCAL_IMAGES from '../utils/localImages';
import { galleryImagesData } from '../utils/dummydata';
import NewFooter from '../footer';

export default function Gallery() {
      const settings = {
        dots:false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            //   dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
 
  return (
    <div style={{}}>
            <div className='flex justify-between items-center gap-x-2 gap-y-5 flex-wrap mx-10'>
              {
                galleryImagesData?.map((item,index)=>(
                  <div className='w-[300px] h-[250px]' key={index}>
                    <img src={item.galleryimg} className='w-full h-full'/>
                  </div>
                ))
              }
            </div>    
         <div
          className='galleymapslider'
          >
        <Slider {...settings}>
        {
                galleryImagesData?.map((item,index)=>{
                    return(
                        <div style={{display:'flex',width: 'calc(100% /2- 10px)',}}
                         >
                            <img src={item.galleryimg}
                            style={{height:'200px',width:'440px',}}
                            alt='galleryimages'
                            />
                        </div>
                    )

                })
            }
        </Slider>
      </div> 
      <NewFooter/>
    </div>
  )
}
