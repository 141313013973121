import React from 'react'


const ProductCart = ({img,desc}) => {
  return (
    <div className=' w-[400px] h-[500px] flex flex-col '>

    <img src={img} alt={desc} className='w-full h-[350px] cursor-pointer' style={{ filter: "brightness(95%)", backgroundColor: "#F9F1E0" }} />

    <div className='border-t-2  flex flex-col '>
      
      <div className='flex my-2 flex-wrap'>
        <p className='text-md md:text-lg text-black font-semibold text-center'>{desc}</p>
      </div>
      
      <button className='flex justify-center items-center bg-black text-white font-semibold text-xl px-5 py-2 cursor-pointer hover:bg-gray-800 hover:text-slate-100 duration-150 ease-in-out' >Avail This Offer</button>
    </div>
    </div>
  )
}

export default ProductCart