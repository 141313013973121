import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import left from '../../assets/images/left1.png';
import keratin from '../../assets/images/keratin.png';
import smoothening from '../../assets/images/smoothening.png';
import globalhaircolor from '../../assets/images/globalhaircolor.png';
import ProductCart from '../../components/ProductCart';
import AvailCart from '../../components/AvailCart';
import highlight from '../../assets/images/highlight.png';
import Balayage from '../../assets/images/balayage.png';
import Oplaplex from '../../assets/images/olaplex.png';
import AdvanceHaircut from '../../assets/images/advancehaircut.png';
import BridalMakeup from '../../assets/images/MakeupBridal.png';
import hairbanner from '../../assets/images/hairbanner.png';
import rating from '../../assets/images/rating.png';
import { FiPhoneCall } from "react-icons/fi";

const CustomDesignSalons = () => {
    const params = useParams();
    const [singleSalonDetail, setSingleSalonDetail] = useState({});
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchAPI() {
            try {
                const response = await axios.get(`https://smartsalon.in/api/getSingleSalonDetail?id=${params.id}`);
                // const response = await axios.get(`http://192.168.2.31:4000/api/getSingleSalonDetail?id=${params.id}`);
                const data = response.data.data;
                console.log("Fetched Data:", data);
                setSingleSalonDetail(data);
                setLatitude(data.latitude);
                setLongitude(data.longitude);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching salon details:", error);
                setIsLoading(false);
            }
        }
        fetchAPI();
    }, [params.id]);

    useEffect(() => {
        console.log("Updated Coordinates:", { latitude, longitude });
    }, [latitude, longitude]);

    const mapContainerStyle = {
        width: '100%',
        height: '450px'
    };

    const center = {
        lat: latitude,
        lng: longitude
    };

    const url =
        singleSalonDetail &&
        singleSalonDetail.images &&
        singleSalonDetail.images[0] &&
        singleSalonDetail.images[0].imageUrl;
        const apiKey = process.env.REACT_APP_MAP_API_KEY;

    return (
        <div className='w-full h-auto flex flex-col roboto-medium'>
            {/* 1 */}
            <div className='flex flex-col md:flex-row justify-between items-center w-full'>
                <div className='w-full md:w-1/2 h-auto md:h-[600px]'>
                    <img src={left} className='w-full h-full' alt="Salon" />
                </div>
                <div className='w-full md:w-1/2 h-auto md:h-[600px] py-10 px-10'>
                    <h1 className='w-full py-2 text-black text-center font-normal text-2xl tracking-wider'>{singleSalonDetail?.address2}</h1>
                    <img src={rating} className='w-1/2 mx-auto' alt="Rating" />
                    <form className='flex flex-col justify-center items-center gap-y-5'>
                        <input placeholder='Your Name' className='border-2 border-black w-full py-5 px-5 rounded-lg outline-none placeholder:text-black' />
                        <input placeholder='Your Phone Number' className='border-2 border-black w-full py-5 px-5 rounded-lg outline-none placeholder:text-black' />
                        <input placeholder='Your Preferred Location' className='border-2 border-black w-full py-5 px-5 rounded-lg outline-none placeholder:text-black' />
                        <button className='w-full bg-black text-white py-4 px-3 text-lg'>Book an appointment</button>
                    </form>
                </div>
            </div>
            {/* 2 */}
            <h1 className='w-full bg-black py-6 px-3 text-white text-center font-bold text-2xl md:text-5xl my-14'>Offers & Discounts</h1>
            <div className='flex flex-col md:flex-row justify-between items-center my-5'>
                <ProductCart img={keratin} desc={"Upto 40% Off: Keratin Treatment"} />
                <ProductCart img={smoothening} desc={"Upto 40% Off: Smoothening / Straightening"} />
                <ProductCart img={globalhaircolor} desc={"Upto 40% Off: Global Hair Color"} />
            </div>
            <div className='flex flex-col md:flex-row justify-between items-center my-5'>
                <AvailCart img={highlight} desc={"Upto 40% Off: Highlights"} />
                <AvailCart img={Balayage} desc={"Upto 40% Off: Balayage"} />
                <AvailCart img={Oplaplex} desc={"Upto 40% Off: Olaplex Treatment"} />
                <AvailCart img={AdvanceHaircut} desc={"Upto 40% Off: Advance Haircut + Spa"} />
            </div>
            <div className='my-10 w-full h-auto'>
                <img src={BridalMakeup} className='w-full h-full' alt="Bridal Makeup" />
            </div>

            <h1 className='w-full py-6 px-3 text-black text-center font-bold text-3xl md:text-5xl'>About Us</h1>
            <div className='flex flex-col md:flex-row justify-between items-center w-full'>
                <div className='w-full md:w-1/2 h-auto md:h-[500px] py-10 px-5'>
                    {url && <img src={url} className='w-full h-full' alt="Salon Detail" />}
                </div>
                <div className='w-full md:w-1/2 h-auto md:h-[500px]'>
                    <p className='text-xl px-10 py-10 tracking-widest'>
                        {singleSalonDetail?.name} {singleSalonDetail?.address2} gives you the ultimate style with the cuts and styling that are best suited to your needs. You can get the expert consultation services of our qualified stylists, who will help you get rid of those unwanted blemishes, hair, fine lines and help you look your best. Monsoon Salon Mayur Vihar Phase 1 is a salon and spa in the proximity of New Delhi, India. Monsoon Salon has been offering services to the people of Delhi NCR for more than 12+ Years. We are offering treatments for hair, nails, facials and body treatments at affordable prices. In addition, we also offer other services like waxing and makeovers. Our team members are highly qualified in their respective fields and they use only natural ingredients for all the treatments.
                    </p>
                    <h1 className='w-full bg-black py-6 px-3 text-white text-center font-bold text-2xl'>Get Ultimate Hair Makeover</h1>
                </div>
            </div>
            {/* Gallery */}
            <h1 className='w-full bg-black py-6 px-3 text-white text-center font-bold text-5xl my-10 tracking-wider'>Gallery</h1>
            <div className='w-full mb-10'>
                <img src={hairbanner} className='w-full' alt="Hair Banner" />
            </div>
            {/* Palor Details */}
            <div className='w-full bg-black text-white'>
                <h1 className='w-full py-5 text-white text-center font-bold text-5xl tracking-wider'>Reach Us</h1>
                <h1 className='w-full py-2 text-white text-center font-normal text-2xl tracking-wider'>{singleSalonDetail?.address}</h1>
                <h1 className='w-full py-2 text-white text-center font-normal text-2xl tracking-wider'> {singleSalonDetail?.address2}</h1>
                <div className='w-full py-2 my-5 text-white text-center font-bold text-4xl tracking-wider flex justify-center items-center gap-x-5'>
                    <FiPhoneCall /> 91-{singleSalonDetail?.phoneNumber}
                </div>
            </div>
            {!isLoading && latitude && longitude && (
                <LoadScript googleMapsApiKey={apiKey}>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={15}
                    >
                        <Marker position={center} />
                    </GoogleMap>
                </LoadScript>
            )}
        </div>
    );
};

export default CustomDesignSalons;