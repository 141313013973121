import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./newfranchise.css";
import { Helmet } from "react-helmet";
import { FaCheck } from "react-icons/fa6";

import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LOCAL_IMAGES from "../utils/localImages";
import Footer from "../footer/Footer";
import MainText from "../../components/mainTextcomponent";
import NewFooter from "../footer";
import { brandsImg, franchiseBestData } from "../utils/dummydata";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { FaPersonBooth } from "react-icons/fa6";
import { FaCity } from "react-icons/fa6";
import { debounce } from "lodash";

const states = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Chandigarh',
  "Lakshadweep",
  'Delhi',
  "Puducherry",
  "Ladakh",
  "Lakshadweep",
  "Jammu and Kashmir"

 

]

export default function Newfranchise() {
  const GTM_ID = "GTM-T37QZ7DB";
  const salonData = [
    {
      name: "Monsoon",
    },

    {
      name: "Monsoon Pro",
    },
    {
      name: "Smart Salon",
    },
  ];
  const salonBudgetdata = [
    {
      name: "15 lac to 35 lac",
    },
    {
      name: "35 lac to 55 lac",
    },
    {
      name: "55 lac  to 1cr",
    },
  ];
  const salonFranchiseDiscription = [

    {
      image: <FaLocationArrow />,
      heading: "230+ locations",
      subHeading: "Two unique business solutions: Salon & Studio",
    },
    {
      image: <FaPersonBooth />,
      heading: "3000+ Artists & Technicians",
      subHeading: "Globally recognised products and services",
    },
    {
      image: <FaCity />,
      heading: "35+ Cities Pan-India",
      subHeading: "Continued Business Support Operations, HR, Marketing",
    },
    {
      image: <FaRegHourglassHalf />,
      heading: "8 millions people served till date ",
      subHeading: "A legacy of trust and quality service",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [firstname, setFirstName] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [phoneError, setphoneError] = useState("");
  const [comment, setComment] = useState("");
  console.log("phoneError", emailError, phoneError);
  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState("")
  const [suggestCity,setSuggestCity] = useState([])

  const [salonType, setSalonType] = useState("");
  const [service, setService] = useState("");

  console.log("servicedata", emailError);
  const labelStyles = {
    color: "grey", // Change this to the color you desire at time and date
  };

  const validatePhoneNumber = () => {
    const Regex = /^([0-9]{10})$/;
    const emailRgx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameRegexMin2 = /^[a-zA-Z ]{2,50}$/;
    if (phoneno === "") {
      setphoneError("Phone Number field cannot be empty");
    } else if (!Regex.test(phoneno)) {
      setphoneError("Invalid phone number");
    } else {
      setphoneError("");
    }
    if (email === "") {
      setEmailError("Email field cannot be empty");
    } else if (!emailRgx.test(email)) {
      setEmailError("Invalid Email Id");
    } else {
      setEmailError("");
    }
    if (firstname === "") {
      setNameError("Name field cannot be empty");
    } else if (!nameRegexMin2.test(firstname)) {
      setNameError("Invalid Name");
    } else {
      setNameError("");
    }
  };
  const handleChangeName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangephoneno = (e) => {
    setPhoneno(e.target.value);
  };
  const handleChangecomment = (e) => {
    setComment(e.target.value);
  };
  const onchangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangesalonType = (e) => {
    setSalonType(e.target.value);
  };
  const handleChangeservice = (e) => {
    setService(e.target.value);
  };

  const handlePostapiCall = () => {
    // alert("inside sucess");

    var data = {
      firstName: firstname,
      lastName: lastName,
      description: comment,
      email: email,
      phoneNumber: phoneno,
      salonType: salonType,
      salonBudget: service,
      state: selectedState,
      city: selectedCity
      // otp: otp
    };
    axios
      .post("https://smartsalon.in/api/formFilling", data)
      // .post("http://192.168.2.243:4000/api/formFilling", data)

      .then(function (response) {
        // handle success
        if (response) {
          setPhoneno("");
          setEmail("");
          setFirstName("");
          setComment("");
          setSalonType("");
          setService("");
          setLastName("");
          setSelectedCity("");
          setSelectedState("")
          alert("form submitted sucessfully");
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        alert("details are incorrect");
      });
  };
  const submithandle = () => {
    validatePhoneNumber();

    if (
      email &&
      phoneno &&
      comment &&
      firstname &&
      selectedState &&
      lastName &&
      service &&
      salonType &&
      phoneError == "" &&
      emailError == ""
    ) {
      handlePostapiCall();
    }

    // validatePhoneNumber()
  };
  // Debounced version of handleCitySelect with a delay of 300ms
  const debouncedHandleCitySelect = debounce(async (value) => {
    if (value.length > 3) {
      const response = await axios.get(`https://api.postalpincode.in/postoffice/${value}`);
      setSuggestCity(response.data[0]?.PostOffice || []);
    }
  }, 200);
  const handleCitySelect=async(e)=>{
    setSelectedCity(e.target.value)
    let myValue = e.target.value;
    console.log("cityyyy",e.target.value)
    debouncedHandleCitySelect(myValue);
  }
  
  console.log("suggestCity",suggestCity)
  const handlingSelectionCity=(name)=>{
    setSelectedCity(name);
    setSuggestCity([])    
  }
  return (
    <>

      <Box>
        <div
          // className='videoContainer'
          className="video-container"
        >
          <Helmet>
            <title>Join the Fast-Growing Beauty Industry with Smart Salon Franchise in India</title>
            <meta name="description" content="Franchise at Smart Salon India and join the booming beauty industry. With low investment costs and comprehensive training, start your journey towards success and unlock your potential as a beauty salon owner today." />
            <meta name="keywords" content="smart salon franchise, smart salon franchise in India, affordable salon franchise in india, top salon franchise opportunities in india, franchise for salon" />
          </Helmet>
          {/* <video controls
            style={{ height: '100%', width: '100%' }}>
            <source src={'https://monsoonsalon.com/wp-content/uploads/2021/08/1st-Branch-Ambience-1-1-1.mp4'} type="video/mp4" />
          </video> */}
          {/* <iframe width="100%" className="youtubeVideo" src="https://www.youtube.com/embed/vVxTlq2jMaU?si=YoYr0k3Z3GE39jys" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <iframe
            width="100%"
            height="567"
            src="https://www.youtube.com/embed/eRIVTe2XSv8"
            title="Monsoon Salon | The fastest growing salon chain in India"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div style={{ bgcolor: "white" }}>
          <div className="">
            {/* <div className="bookappointment">
            <span className="onlinetext"
            >      Why Salon Franchising</span>
            <div sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', }}>
              <img src={LOCAL_IMAGES.lineIcon} alt=''
              />
            </div>

          </div> */}
            <MainText textdata={"Why Salon Franchising"} />

            <div className="ourbooking">
              <Typography className="bookingtext">
                Franchising allows people with no background in the Industry to
                learn and be business owners
              </Typography>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ bgcolor: "", marginLeft: "15px", marginRight: "15px" }}
              >
                <Grid item lg={6} xs={12} sx={{ bgcolor: "" }}>
                  <Grid
                    container
                    spacing={3}
                    sx={{ bgcolor: "", marginTop: "25px" }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        size="small"
                        value={firstname}
                        required
                        fullWidth
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangeName}
                      />
                      <Typography style={{ color: "red" }}>
                        {nameError}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        value={lastName}
                        onChange={onchangeLastName}
                      />
                      <Typography style={{ color: "red" }}>
                        {lastNameError}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        size="small"
                        required
                        value={email}
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end" onClick={onclickOtp}>
                        //       Send Otp
                        //     </InputAdornment>
                        //   ),
                        // }}
                        fullWidth
                        onChange={handleChangeEmail}
                      />
                      <Typography style={{ color: "red" }}>
                        {emailError}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Phone no"
                        variant="outlined"
                        size="small"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangephoneno}
                        value={phoneno}
                      />
                      <Typography style={{ color: "red" }}>
                        {phoneError}
                      </Typography>
                    </Grid>
                    

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl size="medium" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                        Select State*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedState}
                          size="small"
                          label="Select State*"
                          onChange={(e)=>setSelectedState(e.target.value)}
                          width
                        >
                          {states?.map((item, index) => {
                            // console.log('jsonsdata',item)
                            return (
                              <MenuItem value={item}>
                                {" "}
                                {item}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{position:'relative'}}>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Search City"
                        variant="outlined"
                        size="small"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={(e)=>handleCitySelect(e)}
                        value={selectedCity}
                      />{
                        suggestCity?.length>0 && selectedCity && (
                      <div className="w-[93%] mx-auto h-[150px] overflow-y-auto bg-white absolute z-10 shadow-xl">
                        {
                          suggestCity?.map((item)=>(
                            <p className="w-full text-black px-4 py-2 hover:bg-slate-300 hover:scale-95 transition-all duration-300 ease-in-out cursor-pointer" onClick={()=>handlingSelectionCity(item?.Name)}>{item?.Name}</p>
                          ))
                        }
                      </div>
                        )
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl size="medium" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          SalonType*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={salonType}
                          size="small"
                          label="SalonType*"
                          onChange={handleChangesalonType}
                          width
                        >
                          {salonData?.map((item, index) => {
                            // console.log('jsonsdata',item)
                            return (
                              <MenuItem value={item.name}>
                                {" "}
                                {item.name}{" "}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        size="medium"
                        // style={{width:'502px'}}
                        fullWidth
                      >
                        <InputLabel id="demo-simple-select-label">
                          Budget
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={service}
                          size="small"
                          label="Service Interested In*"
                          onChange={handleChangeservice}
                          width
                        >
                          {salonBudgetdata?.map((item, index) => {
                            return (
                              <MenuItem value={item.name}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={12} xs={12}>
                      <TextField
                        fullWidth
                        label="Comments or Message "
                        id="fullWidth"
                        required
                        inputProps={{ style: { color: "grey", fontSize: 18 } }}
                        InputLabelProps={{
                          style: { color: "#626A70", fontWeight: "700" },
                        }}
                        onChange={handleChangecomment}
                        multiline
                        value={comment}
                        rows={3}
                      />
                    </Grid>
                  </Grid>
                  <div className="" style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={submithandle}
                      sx={{ marginLeft: 0, marginTop: 2, bgcolor: "grey" }}
                    >
                      Submit
                    </Button>
                  </div>

                  {/* <p className="ortextcall">Call us @   <p className="phoneno">   +919919898006</p>  <p className="phoneno">,+918826345211</p> </p> */}
                </Grid>
                <Grid item lg={6} xs={12} md={6}>
                  <img
                    src={LOCAL_IMAGES.franchisepoints}
                    alt="salonbooking"
                    className="appointmentimg"
                  />
                </Grid>

                {/* 
         
          */}
              </Grid>
            </div>
            <div className="bg-gray-200 flex flex-col items-center py-5 md:py-14">
              <div>
                <h2 className="text-center text-2xl font-semibold">Truths</h2>
                <p className="text-center text-lg mt-3">
                  India’s most stable, growing salon chain
                </p>
              </div>

              <div className="flex flex-wrap justify-center items-center ">
                {salonFranchiseDiscription?.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center mt-12 mx-4"
                  >

                    {item.image} 
                    <p className="text-center mt-4 font-medium" style={{ fontSize: '20px' }}>
                      {item.heading}
                    </p>

                    <p className="text-center mt-2 text-gray-600 text-[16px] ">
                      {item.subHeading}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="">
              <h1 className="bookappointmentsalon">
                Looking For Salon Franchise In India
              </h1>

              <p className="discriptionText">
                Choose Smart Salon Monsoon Salon is a business that is always
                changing. We are a company of salons that use technology to run
                their businesses more efficiently and earn 30% more money. Our
                ecosystem works with both online and offline systems, which
                gives us an edge over our competitors. There are more than 115
                Monsoon Salon franchises and more than 1,000 beauty and makeup
                experts all over India. It is the fastest-growing salon and the
                best salon franchise in India. Also on the list of the best
                salon franchises in India is Monsoon Salon.
              </p>
            </div>
            <div className="">
              {/* <h1 className="bookappointmentsalon">Looking For Salon Franchise In India
            </h1> */}

              {/* <img
        src="https://monsoonsalon.com/wp-content/uploads/2021/01/0Y2A0396-scaled-e1638956901369.jpg"
        alt=""
        className="responsive-image"
      /> */}
              <div className="" style={{ margin: 80 }}>
                <h1
                  style={{
                    fontSize: "20px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Why is Smart Salon Franchise the Best?
                </h1>
                {franchiseBestData?.map((item, index) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaCheck />
                      <p style={{ marginLeft: 10 }}>{item.discrption}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          
            <div
              style={{
                marginTop: 0,
                borderWidth: 3,
                padding: 50,
                background:"#C8C8C8",
              }}
            >
              <div style={{ background:"#C8C8C8" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontSize: 26, fontWeight: "bold" }}>
                    {"One Move Closer"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <p className="toknow">
                    To know more, get in touch with us at
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="emailText">
                    Email:{" "}
                    <a
                      href="mailto:info@theprofessionalworld.com"
                      style={{ color: "black", fontSize: 16 }}
                    >
                      {" "}
                      info@theprofessionalworld.com
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="emailText">
                    Mobile:
                    <a
                      href="tel:+91-7703900678"
                      style={{ color: "black", fontSize: 16 }}
                    >
                      +91-7703900678
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="emailText">
                    Mobile:
                    <a
                      href="tel:+91-7703900678"
                      style={{ color: "black", fontSize: 16 }}
                    >
                      +91-9315788084
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <MainText textdata={"Partner Brands"} />
            <div
            // style={{ marginTop: 10, marginBottom: '10px', height: '100px', backgroundColor: '', justifyContent: 'center', alignItems: 'center' }}
            >
              <Slider {...settings}>
                {brandsImg?.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "green",
                      }}
                    >
                      <img
                        src={item.brandUrl}
                        style={{ height: "60%", width: "50%" }}
                        alt="galleryimages"
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        {/* <Footer/> */}
        <NewFooter />
      </Box>
    </>
  );
}
