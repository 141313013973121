import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import left from "../../assets/images/left1.png";
import keratin from "../../assets/images/keratin.png";
import smoothening from "../../assets/images/smoothening.png";
import globalhaircolor from "../../assets/images/globalhaircolor.png";
import ProductCart from "../../components/ProductCart";
import AvailCart from "../../components/AvailCart";
import highlight from "../../assets/images/highlight.png";
import Balayage from "../../assets/images/balayage.png";
import Oplaplex from "../../assets/images/olaplex.png";
import AdvanceHaircut from "../../assets/images/advancehaircut.png";
import BridalMakeup from "../../assets/images/MakeupBridal.png";
import hairbanner from "../../assets/images/hairbanner.png";
import rating from "../../assets/images/rating.png";
import { FiPhoneCall } from "react-icons/fi";
import { galleryImagesData } from "../utils/dummydata";
import Slider from "react-slick";
import NewFooter from "../footer";
import LOCAL_IMAGES from "../utils/localImages";

const CustomDesign = () => {
  const params = useParams();
  const location = useLocation();
  const [singleSalonDetail, setSingleSalonDetail] = useState({});
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState([]);

  const apiKey = process.env.REACT_APP_MAP_API_KEY;

  console.log("singleSalonDetail", singleSalonDetail.name);
  const google = window.google;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await axios.get(
          `https://smartsalon.in/api/getSingleSalonDetail?id=${params?.id}`
          //  `https://prosaloncart.com/user/getSingleProductData?id=${shortUrl}`
        );
        const data = response.data.data;
        console.log("Fetched Data:", data);
        setSingleSalonDetail(data);
        setLatitude(data?.latitude);
        setLongitude(data?.longitude);
        setIsLoading(false);
        setMainImage(data?.images);
      } catch (error) {
        console.error("Error fetching salon details:", error);
        setIsLoading(false);
      }
    }
    fetchAPI();
  }, [params.id]);

  // useEffect(() => {
  //   console.log("Updated Coordinates:", { latitude, longitude });
  // }, [latitude, longitude]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full sm:h-150 md:h-[450px] lg:h-320 xl:h-126 2xl:h-[720px]">
        {
          singleSalonDetail.name=="Smart Salon" &&  <img
          src={LOCAL_IMAGES.proplussalon}
          className="w-full h-full"
          alt="Salon Banner"
        />
        }
         {
          singleSalonDetail.name=="Monsoon Pro" &&  <img
          src={LOCAL_IMAGES.monsoonprosalon}
          className="w-full h-full"
          alt="Salon Banner"
        />
        }
           {
          singleSalonDetail.name=="Monsoon" &&  <img
          src={LOCAL_IMAGES.monsoonsalon}
          className="w-full h-full"
          alt="Salon Banner"
        />
        }
      
      </div>

      <h1
        className="text-center  text-xl md:text-3xl mt-4"
        style={{
          color: "#6C757D",
          fontSize: "25px",
          lineHeight: "30px",
          letterSpacing: "0.25",
          fontFamily: "Montserrat",
        }}
      >
        {singleSalonDetail?.address}{" "}
        <span className="font-medium">{singleSalonDetail?.address2}</span>
      </h1>

      <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-8 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <div className="flex-1">
          <p
            className="font-bold text-lg md:text-xl"
            style={{
              fontSize: "22px",
              color: "#6C757D",
              fontWeight: "600px",
              letterSpacing: 0.25,
            }}
          >
            Best Salon in {singleSalonDetail?.address}{" "}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
          <p
            className="mt-4 text-sm md:text-base"
            style={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "400px",
              lineHeight: "22px",
            }}
          >
            {`Looking for a rejuvenating salon experience in the heart of `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`? Look no further! Smart salon Salon is your go-to destination for top-notch beauty and grooming services that will leave you feeling and looking your best. We’re proud to be the premier salon in `}
            <span className="font-bold">{singleSalonDetail.address2}</span>
            {`, and we’re conveniently located to serve you, whether you’re a local resident or just passing through.`}
          </p>
          <p
            className="mt-4 text-sm md:text-base"
            style={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "400px",
              lineHeight: "22px",
            }}
          >
            <span className="font-bold">Expert Stylists:</span>
            {` Our highly skilled and experienced stylists are dedicated to making your salon visit in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {` a memorable one. They stay updated with the latest trends in hair, skincare, and beauty to provide you with cutting-edge services.`}
          </p>
          <p
            className="mt-4 text-sm md:text-base"
            style={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "400px",
              lineHeight: "22px",
            }}
          >
            <span className="font-bold">Premium Products:</span>
            {` We use only the finest, salon-quality products to ensure that your hair and skin receive the care they deserve. Our product range includes some of the most trusted names in the beauty industry. Best salon in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
        </div>

        <img
  src={mainImage[1]?.imageUrl}
  className="w-full md:w-1/2 aspect-[16/9] object-cover"
  alt="Salon Detail"
/>
      </div>

      <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-8 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <div className="flex-1">
          {isLoading && latitude && longitude ? (
            <LoadScript googleMapsApiKey={apiKey}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          ) : (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3504.6701034106613!2d77.267363!3d28.549634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce24a702d1c55%3A0x9a5500a8d4eee992!2sMonsoon%20Salon%20Head%20Office!5e0!3m2!1sen!2sin!4v1695618174537!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
        </div>

        <div className="flex-1 ">
          <p
            className="text-lg md:text-xl font-bold"
            style={{
              color: "#6C757D",
              fontSize: "22px",
              lineHeight: "30px",
              letterSpacing: "0.25",
              fontFamily: "Montserrat",
            }}
          >
            {`Hair Salon in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
          <p
            className="mt-4 text-sm md:text-base"
            style={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "400px",
              lineHeight: "22px",
            }}
          >
            {` Discover the difference of a salon that cares about your beauty and well-being. Schedule an appointment with us today and experience the excellence that has made us the top choice for those seeking a `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`. Let us enhance your natural beauty and provide you with a refreshing escape from the hustle and bustle of daily life`}
          </p>
          <p
            className="mt-4 text-sm md:text-base"
            style={{
              fontSize: "16px",
              color: "#000000",
              fontWeight: "400px",
              lineHeight: "22px",
            }}
          >
            {`We understand that convenience is key, which is why we’re proud to be the salon that’s “near me” for many residents and visitors in  `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`. Our central location makes it easy for you to stop by for a quick touch-up or a full day of pampering.`}
          </p>
        </div>
      </div>
      <div>
        <div className="flex flex-wrap justify-center gap-4 mt-10">
          <div className="flex flex-col p-4">
            <span
              className="text-lg font-bold text-black"
              style={{ fontSize: "22px", textAlign: "center" }}
            >
              Website:
            </span>
            <span>
              <a href="https://www.smartsalon.in" className="text-gray-600">
                smartsalon.com
              </a>
            </span>
          </div>
          <div className="flex flex-col p-4">
            <span
              className="text-lg font-bold"
              style={{ fontSize: "22px", textAlign: "center" }}
            >
              Email Id:
            </span>
            <span>
              <a
                href="mailto:enquiry@theprofessionalworld.com"
                className="text-gray-600 ml-1"
              >
                enquiry@theprofessionalworld.com
              </a>
            </span>
          </div>
          <div className="flex flex-col p-4">
            <span
              className="text-lg font-bold"
              style={{ fontSize: "22px", textAlign: "center" }}
            >
              Phone:
            </span>
            <span className="text-base font-normal">
              <a
                href={`tel:${singleSalonDetail?.phoneNumber}`}
                className="text-gray-600 ml-1"
              >
                {singleSalonDetail?.phoneNumber}
              </a>
            </span>
          </div>
          <div className="flex flex-col p-4 ">
            <span
              className="text-lg font-bold"
              style={{ fontSize: "22px", textAlign: "center" }}
            >
              WhatsApp to Book:
            </span>
            <span>
              <a
                href={`https://wa.me/${singleSalonDetail?.phoneNumber}`}
                className="text-gray-600 ml-1 "
                style={{ textAlign: "center", alignItems: "center" }}
              >
                Click Here to Chat
              </a>
            </span>
          </div>
        </div>
      </div>

      <div className="w-full mt-8">
        <Slider {...settings}>
          {mainImage?.map((item, index) => (
            <div key={index} className="p-2">
              <img
                src={item.imageUrl}
                className="h-52 w-full object-cover"
                alt="Gallery Images"
              />
            </div>
          ))}
        </Slider>
      </div>
      <NewFooter />
    </div>
  );
};

export default CustomDesign;
