import React from 'react'
import { BsInstagram } from "react-icons/bs";
import { FaFacebook, FaYoutube, FaTwitter, FaPinterest } from "react-icons/fa";
import { Link } from 'react-router-dom';

const NewFooter = () => {
   return (
      <div className='px-5 py-5 md:p-20 flex flex-col bg-black text-white w-full merriweather-bold-italic '>
         <div className='flex justify-between gap-x-3 md:justify-around items-start  text-lg font-bold flex-wrap'>
            <div className='flex flex-col'>
               <h1 className='text-xl md:text-md mb-5 font-bold text-white '>Information</h1>
               <Link ><h1 className='text-sm font-semibold text-white cursor-pointer'>Carrer</h1></Link>
               <Link to={"/privacypolicy"}><h1 className='text-sm font-semibold text-white cursor-pointer'>Privacy Policy</h1></Link>
            </div>
            <div className='flex flex-col'>
               <h1 className='text-xl md:text-md mb-5 font-bold text-white '>Join us</h1>
               <Link to={"/contactus"}><h1 className='text-sm font-semibold text-white cursor-pointer'>Contact us</h1></Link>
               <Link to={"/bookappointment"}><h1 className='text-sm font-semibold text-white cursor-pointer'>Book Appointment</h1></Link>
               <Link to={"/franchise"}><h1 className='text-sm font-semibold text-white cursor-pointer'>Franchise</h1></Link>
            </div>
            {/* <div className='flex flex-col'>
          <h1 className='text-xs md:text-md mb-5 font-semibold text-white'>Policies</h1>
          <Link to={"/PrivacyPolicy"}><h1 className='text-xs mt-2 font-semibold text-white cursor-pointer'>Privacy Policy</h1></Link>
          <Link to={"/TermsAndCondition"}><h1 className='text-xs mt-2 font-semibold text-white cursor-pointer'>Terms & Conditions</h1></Link>
          <Link to={"/ReturnRefund"}><h1 className='text-xs mt-2 font-semibold text-white cursor-pointer'>Refund & Return Policy</h1></Link>
       </div> */}
            <div className='flex flex-col'>
               <h1 className='text-xl md:text-md mb-5 font-semibold text-white mt-7 md:mt-0'>Get in touch</h1>
               <h1 className='text-sm font-semibold text-white cursor-pointer'><span>MobileNo:  </span>7703900678</h1>
               <h1 className='text-sm font-semibold text-white cursor-pointer'> <span>MobileNo:  </span>9315788084</h1>
               <h1 className='text-sm font-semibold text-white cursor-pointer'><span>Email: </span>info@theprofessionalworld.com</h1>
            </div>
            <div className='flex flex-col'>
               <h1 className='text-xl md:text-md mb-5 font-semibold text-white  mt-7 md:mt-0'>Our Social Media</h1>
               <div className='flex flex-row gap-x-3 md:gap-x-5'>
                  <a href={"https://www.instagram.com/monsoonsalon/"}><h1 className='text-md font-semibold text-white cursor-pointer hover:scale-110'><BsInstagram /></h1></a>
                  <a href={"https://www.facebook.com/monsoonsalon/"}><h1 className='text-md font-semibold text-white cursor-pointer hover:scale-110'><FaFacebook /></h1></a>
                  <a href={"https://www.youtube.com/user/monsoonsalon"}><h1 className='text-md font-semibold text-white cursor-pointer hover:scale-110'><FaYoutube /></h1></a>
                  <a href={"https://twitter.com/monsoonsalon"}><h1 className='text-md font-semibold text-white cursor-pointer hover:scale-110'><FaTwitter /></h1></a>
                  <a href={"https://in.pinterest.com/monsoonsalon/"}><h1 className='text-md font-semibold text-white cursor-pointer hover:scale-110'><FaPinterest /></h1></a>
               </div>
            </div>
         </div>
         <div className='border-t-2 border-white mt-4'>
            <h1 className='text-sm md:text-md mb-5 font-semibold text-white text-center mt-3'>The Professional Hair Salon & Spa (India) Pvt. Ltd.</h1>
            <h1 className='text-sm md:text-md mb-5 font-semibold text-white text-center mt-3'>Address: Plot No-31 Okhla Industrial Estate, Phase – 3, South Delhi, New Delhi – 110020</h1>
         </div>
      </div>
   )
}

export default NewFooter